body {
	padding-bottom: 16px;
	background-color: #fafafa;
}

.admin-layout {
	flex-grow: 1;

	.container {
		margin-top: 20px;
		padding: 0;
	}
}

.search-bar {
	display: flex;
	align-items: center;
	padding: 8px;

	& .search-field {
		width: 100%;
	}
}

.sound-picker {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;

	.dropdown {
		flex-grow: 1;
		margin-right: 0.5rem;
	}

	.select {
		width: 100%;
	}
}

.MuiPaper-root,
.MuiMenuItem-root {
	background-color: #fff;
}

.MuiSnackbar-root {
	.MuiAlert-filledSuccess {
		color: #fff;
	}
}

a {
	color: rgba(0, 0, 0, 0.87);
}

.form, form {
	width: 100%;
	margin-top: 8px;

	.submit {
		margin: 24px 0 16px;
	}
}

.app-header {
	.menu {
		display: flex;
	}

	.title {
		margin-right: 50px;
		text-decoration: none;
	}

	.button {
		margin: 8px;
		color: #fff;
	}

	.right-menu {
		margin-left: auto;
	}

	.user-name {
		margin: 8px;
	}

	@media (max-width: 1000px) {
		.user-name {
			display: none;
		}
	}

	.menu-drop-down {
		ul {
			background-color: #fff;
		}
	}
}

.app-list {

	.content-top {
		display: flex;
		justify-content: space-between;
		margin-bottom: 16px;
	}

	.page-title {
		margin: 16px 0;
	}

	.pagination {
		display: flex;
		justify-content: center;
		padding: 20px;
	}
}

.app-table {
	.actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.status-cell {
		& > div {
			display: flex;
			justify-content: center;
		}
	}
}

.app-control-room {
	.paper {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.iframe {
		width: 1280px;
		height: 720px;
		transform-origin: left top;
	}

	.preview {
		position: relative;
	}

	.sidebar {
		padding: 12px;
		margin-bottom: 12px;
	}

	.title {
		text-transform: uppercase;
		font-weight: bold;
	}

	.icon-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.ellipsis {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		display: block;
	}
}

.image-dialog-content,
.video-dialog-content {

	padding-bottom: 0;
	min-height: 77vh;
	display: flex;
	flex-direction: column;
	align-items: center;

	.image-list {
		margin-top: 0;
		margin-bottom: 0;
		overflow-y: visible;
	}

	.image-list-item {
		cursor: pointer;
	}

	.load-more-btn {
		margin: 8px 0;
	}
}

.image-dialog-content {
	.image-bar {
		height: 100%;
		align-items: flex-end;
	}
}

.video-dialog-content {
	.image-bar {
		background-Color: rgba(0, 0, 0, 0.75);

		&.selected {
			background-color: rgba(0, 128, 0, 0.75);
		}
	}
}

.image-dialog-actions {
	.upload-btn {
		margin-right: auto;
		margin-left: 16px;
	}
}

.video-dialog-actions {
	align-items: center;

	.progress {
		margin: auto;
	}

	.refresh-btn {
		margin: auto;
	}

	.upload-btn {
		margin-left: 16px;
	}
}

.app-color-picker {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;

	.picker {
		flex-grow: 1;
		margin-right: 1rem;
	}

	.preview {
		width: 1.5rem;
		height: 1.5rem;
		padding: 3px;
		border-radius: 4px;
		border: solid 1px #c4c4c4;
		cursor: pointer;
	}
}

.media-picker {

	max-width: 345px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.card {
		width: 100%;
	}

	.media-card {
		height: 140px;
	}

	.actions {
		display: block;
	}

	.buttons {
		display: flex;
		width: 100%;
		justify-content: space-between;
		margin-left: 0 !important;
	}

	.icon {
		position: absolute;
		top: 0;
		right: 0;
		background-color: rgba(255, 255, 255, 0.85);
	}
}

.app-info-tab {
	.instructions {
		padding: 16px;

		li, img {
			margin-top: 16px;
		}

		.copy-btn {
			display: inline-block;
		}
	}
}

.app-editor,
.app-control-room {

	.app-settings-box {
		&.full-height {
			height: 100%;
		}

		.box-content {
			padding: 16px;
			display: flex;
			flex-direction: column;
		}

		.box-title {
			margin-bottom: 16px;
		}
	}

	.info {
		display: flex;
		align-items: center;
	}

	.info-icon {
		margin-left: 8px;
	}

	.tab-with-icon {
		display: flex;
		align-items: center;
	}

	.tab-icon {
		margin-left: 8px;
	}
}

.app-control-room {
	.app-bar {
		margin-bottom: 16px;
	}
}

.filter-bar {
	display: flex;
	align-items: center;
	margin: 16px 0;
	padding: 16px;

	.section-label {
		display: inline-block;
		margin-right: 16px;
		justify-content: center;
		font-weight: bold;
		font-size: 15px;

		&:first-of-type {
			min-width: 130px;
		}
	}

	@media (max-width: 1200px) {
		.section-label {
			display: none;
		}
	}

	.MuiFormControl-root {
		min-width: 170px;
		margin-right: 16px;
	}

	@media (max-width: 1000px) {
		.MuiFormControl-root {
			min-width: 160px;
			margin-right: 12px;
		}
	}

	.clear-btn {
		margin-left: 16px;
	}

	.action-btn {
		margin-left: auto;
	}
}

.css-editor-container {
	display: flex;
	flex-direction: column;
	align-items: center;

	.MuiButton-root {
		width: 140px;
	}
}

.MuiFormControl-root.settings-form-control {
	margin-bottom: 24px;
	display: flex;
}

.poster-picker {
	display: block;
}

.livestream-settings-line {
	align-items: center;

	.settings-form-control {
		margin-bottom: 0;
	}

	margin-bottom: 24px;
}

.app-table {
	.livestreams-table-cell {
		li {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.import-data-list-item {
	display: block;
	padding-bottom: 8px;

	b {
		margin-right: 8px;
	}
}

.import-data-close-btn.MuiButton-root {
	margin-left: 8px;
}

.MuiSelect-select {
	.social-type {
		display: inline-block;
		min-width: auto;
		margin-right: 10px;
	}
}

.channel-link,
.livestream-link {
	padding: 3px 0;

	a {
		text-decoration: none;
	}

	.social-icon {
		margin-right: 5px;
	}
}

.livestream-link a {
	display: inline-flex;
	align-items: center;
}

iframe {
	border: none;
}

.select-flag {
	width: 30px;
	margin-right: 10px;
}
