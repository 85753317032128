.quiz-screen {
	overflow: hidden;
	width: 1280px;
	height: 720px;
	display: flex;
	flex-direction: column;
	position: relative;

	&.welcome {
		position: relative;

		.countdown {
			position: absolute;
			width: 100%;
			padding: 0.3em;
		}

		&.top .countdown {
			top: 0
		}

		&.bottom .countdown {
			bottom: 0
		}

		&.middle .countdown {
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&.question {
		.question-text {
			animation: fadeInDown 0.6s;
		}

		.option:nth-of-type(1),
		.option:nth-of-type(3) {
			animation: slideInLeft 0.6s;
		}

		.option:nth-of-type(2),
		.option:nth-of-type(4) {
			animation: slideInRight 0.6s;
		}

		.option:nth-of-type(3),
		.option:nth-of-type(4) {
			animation-duration: 0.8s;
		}
	}

	&.answer {
		.correct {
			animation: tada 0.8s;
		}
	}

	&.answer,
	&.question {
		.question-text {
			text-align: center;
			padding: 0.3em;
			z-index: 1000;
		}

		.countdown-wrap {
			flex-grow: 1;
			display: flex;
			margin: 4px;

			&.v-top {
				align-items: flex-start;
			}

			&.v-center {
				align-items: center;
			}

			&.v-bottom {
				align-items: flex-end;
			}

			&.h-left {
				justify-content: flex-start;
			}

			&.h-center {
				justify-content: center;
			}

			&.h-right {
				justify-content: flex-end;
			}

			.countdown {
				display: flex;
				justify-content: center;
				align-items: center;
				box-sizing: content-box;
				border-style: solid;
				border-radius: 50%;
				animation: pulse 1s infinite;
				z-index: 1000;
			}
		}

		.options {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 2px;
			z-index: 1000;

			.option {
				display: flex;

				.option-index,
				.option-text {
					padding: 0.3em;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
				}

				.option-index {
					font-weight: bold;
				}

				.option-text {
					flex-grow: 1;
				}
			}
		}
	}

	&.answer {
		justify-content: space-between;
	}

	&.scores {
		.screen-title {
			text-align: center;
			padding: 0.3em;
			z-index: 1000;
		}

		.screen-content {
			flex-grow: 1;
			position: relative;
			overflow: hidden;

			.rows::-webkit-scrollbar {
				display: none;
			}

			.rows {
				max-height: 100%;
				overflow: scroll;
				display: grid;
				grid-template-columns: auto auto 1fr auto auto auto;
				padding: 0.1em;

				.row {
					opacity: 0;
					animation: fadeIn;
					animation-duration: 400ms;
					animation-fill-mode: forwards;

					margin: 0.1em;
					display: grid;
					grid-column: 1 / -1;
					grid-template-columns: auto auto 1fr auto auto auto;
					align-items: center;

					.position {
						padding: 0 0.5em;
						font-size: 1.3em;
						text-align: center;
						font-weight: bold;
					}

					.user-img {
						display: flex;

						img {
							width: 2.5em;
							height: 2.5em;
						}
					}

					.user-name,
					.user-team,
					.scores,
					.total-scores {
						padding: 0 1em;
					}

					.user-team {
						text-align: center;
					}

					.scores,
					.total-scores {
						text-align: right;
					}

					.position,
					.scores,
					.total-scores {
						box-sizing: content-box;
					}

					.user-name {
						white-space: nowrap;
						overflow: hidden;
					}

					&:nth-of-type(1) {
						animation-delay: 50ms;
					}

					&:nth-of-type(2) {
						animation-delay: 100ms;
					}

					&:nth-of-type(3) {
						animation-delay: 150ms;
					}

					&:nth-of-type(4) {
						animation-delay: 200ms;
					}

					&:nth-of-type(5) {
						animation-delay: 250ms;
					}

					&:nth-of-type(6) {
						animation-delay: 300ms;
					}

					&:nth-of-type(7) {
						animation-delay: 350ms;
					}

					&:nth-of-type(8) {
						animation-delay: 400ms;
					}

					&:nth-of-type(9) {
						animation-delay: 450ms;
					}

					&:nth-of-type(10) {
						animation-delay: 500ms;
					}

					&:nth-of-type(11) {
						animation-delay: 550ms;
					}

					&:nth-of-type(12) {
						animation-delay: 600ms;
					}

					&:nth-of-type(13) {
						animation-delay: 650ms;
					}

					&:nth-of-type(14) {
						animation-delay: 700ms;
					}

					&:nth-of-type(15) {
						animation-delay: 750ms;
					}

					&:nth-of-type(16) {
						animation-delay: 800ms;
					}

					&:nth-of-type(17) {
						animation-delay: 850ms;
					}

					&:nth-of-type(18) {
						animation-delay: 900ms;
					}

					&:nth-of-type(19) {
						animation-delay: 950ms;
					}

					&:nth-of-type(20) {
						animation-delay: 1000ms;
					}
				}
			}
		}

		.fail-text {
			width: 100%;
			text-align: center;
			position: absolute;
			padding: 0.3em;

			&.top {
				top: 0
			}

			&.bottom {
				bottom: 0
			}

			&.middle {
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	.teams-container {
		display: flex;
		gap: 20px;
		padding: 20px;
		height: 100%;
		justify-content: space-around;
		align-items: center;

		.team-card {
			flex-basis: 40%;
			height: 100%;
			display: flex;
			flex-direction: column;
			transform-origin: bottom right;

			.team-name {
				text-align: center;
				padding: 10px;
				color: #fff;
				font-size: 2rem;
			}

			.team-card__img {
				flex-basis: 50%;
				text-align: center;
				overflow: hidden;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
			}

			.team-card__content {
				flex-basis: 50%;
				padding: 10px;
				background-color: rgba(0, 0, 0, 0.8);
				color: #fff;
				height: 100%;

				display: flex;
				flex-direction: row;

				.team-card__content_col_1 {
					flex-basis: 30%;
				}

				.team-card__content_col_2 {
					flex-basis: 70%;
					padding: 0 0 0 10px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
				}

				.team-card-bar {
					height: 100%;

					.team-card-bar__inner {
						position: relative;
						height: 100%;
						border: solid 1px #000;

						.team-card-bar__col {
							position: absolute;
							bottom: 0;
							height: 0%;
							width: 100%;
							transition: height 1s ease-in-out;
						}

						.team-card-bar__text {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							font-size: 1.7rem;
							text-shadow: #000 0px 0 10px;
						}
					}
				}

				.team-card__content_stats {
					font-size: 1rem;
					display: flex;
					flex-display: row;
					flex-basis: 25%;
					text-transform: uppercase;
					align-items: center;

					.team-card__content_stats__icon {
						flex-basis: 10%;
						font-size: 1.2rem;
					}

					.team-card__content_stats__label {
						flex-basis: 80%;
						text-align: center;
					}

					.team-card__content_stats__value {
						flex-basis: 10%;
						font-weight: bold;
						font-size: 1.2rem;
						text-align: right;
					}

					border-bottom: 1px solid #fff;
				}

				.total-users {
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 100px;
				}
			}

			.team-card__content > .team-card__content_stats:first-child {
				border-top: 1px solid #fff;
			}
		}
	}
}

.countdown {
	text-align: center;
}
