.dashboard {
	.paper {
		padding: 16px;
		height: 100%;
	}

	.table {
		padding-bottom: 16px;
	}

	.table .buttons {
		display: flex;
		justify-content: center;
		padding-top: 16px;
	}

	.grid-item {
		display: flex;
		flex-direction: column;
	}
}