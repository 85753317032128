.app-screen {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	width: 1280px;
	height: 720px;
	transform-origin: left top;
}

.media {
	width: 100%;
	height: 100%;
	position: absolute;

	video {
		width: 100%;
		height: 100%;
	}
}

.screen-title {
	padding: 0.3em;
	text-align: center;
}

.watermark {
	position: absolute;
	z-index: 1000000;
	opacity: 0.85;

	&.mid-right {
		top: 50%;
		right: 10px;
		transform: translateY(-50%);
	}

	&.top-right {
		top: 10px;
		right: 10px;
	}
}

.MuiTypography-root.streaming {
	display: flex;
	align-items: center;
	gap: 10px;
}

.color-youtube {
	color: #ff0000;
}

.color-twitch {
	color: #7b42f0;
}