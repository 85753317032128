.quiz-action-bar {

	display: flex;
	justify-content: flex-end;

	.loader {
		margin-right: 16px;
	}
}

.answer-option.quiz {

	padding: 8px;
	margin-bottom: 16px;

	.line1 {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 8px;
	}

	.form-control {
		margin: 0;
	}

	.icon {
		margin-left: 16px;
	}
}

.app-editor.quiz {
	.questions-tab {
		.btn {
			margin-left: 8px;
			margin-right: 8px;
		}

		.pagination {
			display: flex;
			justify-content: center;
			padding: 20px;
		}
	}

	.final-style-tab {
		.accordion-content.no-padding {
			padding-top: 0;
		}
	}
}

.confirm-dialog.quiz {
	.padding {
		padding: 16px;
	}

	.checkbox {
		margin-right: auto;
	}

	.label {
		font-size: 0.85rem;
	}
}

.quiz-scores-popup {
	.bar {
		position: relative;
	}

	.title {
		margin-left: 16px;
		flex: 1;
	}

	.user {
		display: flex;
		align-items: center;

		.user-name {
			margin-left: 16px;
		}
	}
}

.quiz-question-form {
	.form-box {
		flex-grow: 1;
	}

	.img-box {
		padding-left: 8px;
	}

	.save-btn {
		background-color: #4caf50;
		color: #fff;
		margin-left: 8px;
	}

	.actions {
		display: flex;
		justify-content: center;

		.media-picker {
			flex-direction: row;
			max-width: 100%;
			align-items: center;

			& > div {
				margin-top: 0;
			}
		}
	}

	.quiz-question-form__options {

		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.quiz-question-form__answer-option {
			max-width: 49%;
			flex-basis: 49%;
		}
	}
}


.quiz-question-preview {
	display: flex;
	margin-top: 8px;
	margin-bottom: 8px;
	padding: 8px;
	align-items: center;

	.drag {
		cursor: grab;
		padding: 12px;
		display: flex;
		align-items: center;
	}

	.number {
		font-size: 1.1rem;
		font-weight: bold;
		padding: 12px 12px 12px 0;
	}

	.question-text {
		flex-grow: 1;
	}

	.progress {
		margin: 16px;
	}
}

.app-editor.quiz {

	.preview-style-tab {
		margin-top: 8px;

		.accordion-content {
			display: flex;
			flex-direction: column;
			align-items: center;

			input {
				color: #000;
			}
		}

		.preview {
			position: relative;

			.quiz-screen {
				transform-origin: left top;
			}
		}

		.question-select,
		.screen-select {
			padding: 16px;
			margin-bottom: 16px;

			.MuiFormControl-root {
				margin-bottom: 0;
			}
		}

		.save-box {
			display: flex;
			justify-content: center;
			padding: 16px;
		}

		.side {
			padding: 24px;
		}

		.typography {
			color: rgba(0, 0, 0, 0.54);
			text-transform: uppercase;
		}

		&.final-style-tab {
			.save {
				display: flex;
				justify-content: center;
			}

			.save-box {
				align-items: center;
				flex-direction: column;
				padding: 24px 16px 16px 16px;
			}
		}

		&.answer-style-tab,
		&.welcome-style-tab {
			.side {
				form {
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				input {
					color: #000;
				}
			}
		}

		&.final-style-tab,
		&.question-style-tab,
		&.scores-style-tab {
			form {
				margin-top: 0;
			}
		}
	}
}

.teams-tab {
	.add-team-btn {
		margin-right: 16px;
	}

}

.quiz-team-form {
	.app-color-picker > div:first-child {
		flex-grow: 1;
		padding-right: 16px;
	}

	.media-picker {
		min-height: 140px;
	}
}

.app-control-room.quiz {
	.sidebar.activity-log {
		display: flex;
		flex-direction: column;
		align-items: center;

		.log-line {
			display: flex;
			align-items: center;
			font-size: 13px;

			.time, .img, .MuiSvgIcon-root {
				margin-right: 8px;
			}

			height: 30px;
			overflow: hidden;
		}

		.log-line.chat-log-line {
			.user-name {
				margin-right: 8px;
				font-weight: bold;
				color: rgba(17, 17, 17, 0.6)
			}

			.msg {
				color: rgb(15, 15, 15);
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}

		.game-log-line {
			.action {
				text-transform: capitalize;
			}
		}
	}
}

.quiz-picker {
	display: flex;
	align-items: center;

	.MuiAutocomplete-root {
		margin-left: 16px;
		min-width: 400px;
	}
}
