.app-screen.poll {
	.root {
		height: 100%;
		width: 100%;
		position: relative;
		display: flex;
		justify-content: flex-end;

		&.hleft {
			justify-content: flex-start;
		}
		&.hcenter {
			justify-content: center;
		}
	}

	.chart {
		width: 50%;
		max-width: 50%;
		height: 100%;
	}

	.amcharts5-wrap {
		width: 100%;
		height: 110%;
		margin-top: -5%;
	}

	.counter {
		position: absolute;

		&.hleft {
			left: 0;
		}

		&.hcenter {
			left: 50%;
			transform: translateX(-50%);
		}

		&.hright {
			right: 0;
		}

		&.vtop {
			top: 0;
		}

		&.vcenter {
			top: 50%;
			transform: translateY(-50%);
		}

		&.vbottom {
			bottom: 0
		}
	}
}