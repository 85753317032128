.app-editor.broadcast {
	.ls-divider {
		margin-bottom: 24px;
	}
}

.app-table.broadcast {
	.source-url {
		display: block;
		width: 200px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
