.poll-question-form {

	.select {
		margin-top: 4px;
		margin-bottom: 28px;
	}

	.imgBox {
		margin-top: 8px;
		margin-bottom: 8px;
	}

	.save-btn {
		background-color: #4caf50;
		color: #fff;
		margin-right: 8px;
	}

	.preview {
		position: relative;
	}

	.accordion-content {
		display: flex;
		flex-direction: column;
		align-items: center;

		input {
			color: #000;
		}
	}

	.typography {
		color: rgba(0, 0, 0, 0.54);
		text-transform: uppercase;
	}

	.save-box {
		display: flex;
		justify-content: center;
		padding: 16px;
	}

	.options-chat {
		display: flex;
		flex-direction: column;
		align-items: center;

		.list-item {
			padding-top: 0;
			padding-bottom: 16px;
		}

		.option-form {
			padding: 16px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.form-control {
				padding-right: 8px;
			}

			.options {
				width: 100%;
			}
		}
	}
}

.poll-question-preview {

	display: flex;
	margin-top: 8px;
	margin-bottom: 8px;
	padding: 8px;
	align-items: center;

	.drag {
		cursor: grab;
		padding: 12px;
		display: flex;
		align-items: center;
	}

	.number {
		font-size: 1.1rem;
		font-weight: bold;
		padding: 12px 12px 12px 0;
	}

	.question-text {
		flex-grow: 1;
	}
}

.poll-settings-tab {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: center;

	.image-picker {
		display: block;
	}
}

.app-control-room.poll {
	.poll-list {
		margin: 8px;
	}
}

.poll-export-btn {
	margin-right: 8px;
}
