.user-form {
	.buttons {
		display: flex;
		justify-content: center;

		button {
			margin: 0 8px;
		}
	}

	.fullWidth {
		width: 100%;
	}

	.select {
		margin: 16px 0;
	}
}